import React, { FunctionComponent, useContext, useEffect, useState, useRef, useCallback } from "react";
import { ImageInterface, SanityLabelsInterface } from "../../types/SanityTypes";
import { graphql, useStaticQuery } from "gatsby";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { FreeMode, Pagination } from "swiper";
import { Link } from "../Link";
import ProductCard from "../ProductCard";
import Section from "../Section";
import { LocalizedContext } from "../../services/LocalizedContextService";
import AddToCartAlert from "../AddtoCartAlert";

import "./styles.scss";
import { event11 } from "../../analytics/event11";
import { event12 } from "../../analytics/event12";
import { log } from "node:console";

interface ProductListingBlockInterface {
  _id: string;
  i18n_lang: string;
  heading: string;
  manualProducts?: [
    {
      name: string;
      price: number;
      images: [ImageInterface];
      productCategory: {
        name: string;
      };
    }
  ];
  blockType?: string;
  isVariation?: boolean;
  productCollection?: [
    {
      name: string;
    }
  ];
  productCategory?: [
    {
      name: string;
    }
  ];
  bgColor?: {
    title: string;
    value: string;
  };
  isHomePageVersion?: boolean;
}

const ProductListingBlock: FunctionComponent<ProductListingBlockInterface> = props => {
  const { blockType, manualProducts, heading, isHomePageVersion, bgColor } = props;
  const [width, setWidth] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [linkToProduct, setLinkToProduct] = useState("");
  const _rawProductCollection = props?.productCollection?.map(({ name }) => name) || [];
  const _rawProductCategory = props?.productCategory?.map(({ name }) => name) || [];

  const { sanityLabels, sanitySiteSettings } = useContext(LocalizedContext);
  const { productLabels } = sanityLabels as SanityLabelsInterface;

  const useProducts = (collectionName: Array<string>) => {
    const data = useStaticQuery(graphql`
      query CombinedQuery {
        isVariationData: allSanityProductListingBlock(filter: { name: { eq: "Shop the Collection" } }) {
          nodes {
            id
            isVariation
          }
        }
        productData: allSanityProduct(
          sort: { fields: _createdAt, order: DESC }
          filter: { isHiddenOnWeb: { ne: true } }
        ) {
          nodes {
            _createdAt
            _id
            i18n_lang
            name: paName
            productName
            productIngredients
            price
            slug {
              current
            }
            productCategory {
              name
            }
            productCollection {
              name
            }
            promotion {
              price
            }
            upc
            images: _rawPaImages(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    `);
    let productData;
    const isVariation = data.isVariationData.nodes[0].isVariation;
    if (data.isVariationData.nodes[0].isVariation) {
      productData = data.productData.nodes.filter(({ productCategory }) =>
        productCategory ? productCategory.some(category => collectionName.includes(category.name)) : false
      );
    } else {
      productData = data.productData.nodes.filter(({ productCollection }) =>
        productCollection ? collectionName.includes(productCollection.name) : false
      );
    }

    return {
      productData,
      isVariation
    };
  };
  const { productData, isVariation } = useProducts(_rawProductCategory || _rawProductCollection);

  let productItems = productData;
  if (blockType === "manualChoice") {
    productItems = manualProducts;
  }
  const swiper = useSwiper();
  const breakPointsOptions = {
    centeredSlides: false,
    initialSlide: 0,
    scrollbar: {
      draggable: true
    },
    spaceBetween: 24
  };

  const setBreakPoints = {
    375: {
      ...breakPointsOptions,
      spaceBetween: 16,
      slidesPerView: 1.3
    },
    768: {
      ...breakPointsOptions,
      slidesPerView: 2
    },
    1024: {
      ...breakPointsOptions,
      slidesPerView: 3
    },
    1440: {
      ...breakPointsOptions,
      slidesPerView: 4
    }
  };

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (productItems && productItems.length) {
      event11(productItems, sanitySiteSettings?.brandName, "Product Collection Slider");
    }
  }, [productItems]);

  const handleProductLinkClick = (item: any, index: number) => {
    event12(
      item._id,
      item.name,
      item?.productCategory?.name,
      sanitySiteSettings.brandName,
      index,
      "Product Collection Slider"
    );
  };
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Section
      sectionClassName={`c-productListingBlock ${props._id} ${isHomePageVersion ? "home-page-version" : ""} ${
        isVariation ? "section-variation" : ""
      }`}
      title={heading}
      theme={bgColor?.title}
    >
      {productItems && productItems.length ? (
        <>
          <Swiper
            ref={sliderRef}
            key="product"
            preloadImages={true}
            centeredSlides={true}
            centeredSlidesBounds={true}
            initialSlide={0}
            slidesPerView={"auto"}
            spaceBetween={20}
            simulateTouch={true}
            lazy={true}
            freeMode={true}
            loopedSlides={4}
            loop={false}
            scrollbar={{ draggable: true }}
            preventClicks={true}
            breakpoints={setBreakPoints}
            watchSlidesProgress={true}
            grabCursor={true}
            pagination={{
              type: "progressbar"
            }}
            modules={[Pagination, FreeMode]}
            className={` ${
              isVariation
                ? `swiper-variation ${productItems.length <= 3 && width > 1024 ? "swiper-no-swiping" : ""}`
                : `${productItems.length <= 3 && width > 768 ? "swiper-no-swiping" : ""}`
            }`}
          >
            {productItems
              .filter((item: any) => !item.isHiddenOnWeb)
              .map((item: any, index: number) => (
                <SwiperSlide
                  className="swiper-slide-animation"
                  key={`slider-${index}`}
                  data-testid={`playlist-slide-${index}`}
                  style={{ width: "inherit", height: "auto" }}
                >
                  <Link
                    to={item.slug.current}
                    className="c-productListingBlock__productItem"
                    onClick={() => handleProductLinkClick(item, index)}
                  >
                    <ProductCard
                      isVariation={isVariation}
                      index={index + 1}
                      id={item._id || item.upc}
                      productName={isVariation ? item.productName : item.name}
                      productIngredients={item.productIngredients}
                      image={isVariation ? item.images[2] : item.images[0]}
                      price={item.price}
                      discountedPrice={item.promotion?.price}
                      productCategory={item.productCategory?.name}
                      brandName={sanitySiteSettings?.brandName}
                      productLabels={productLabels}
                      showCategory={true}
                      isHomeVersion={isHomePageVersion}
                      orderable={item.orderable}
                      setAlertMessage={setAlertMessage}
                      setShowAlert={setShowAlert}
                      setLinkToProduct={setLinkToProduct}
                      productLink={item.slug.current}
                      isInSlider={true}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            {isVariation && (
              <>
                <button type="button" className="navigation-button-variation prev-arrow" onClick={handlePrev}>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="15" cy="15" rx="15" ry="15" transform="rotate(90 15 15)" fill="#181818" />
                    <path
                      d="M11.0411 14.4697C10.7482 14.7626 10.7482 15.2374 11.0411 15.5303L15.814 20.3033C16.1069 20.5962 16.5818 20.5962 16.8747 20.3033C17.1676 20.0104 17.1676 19.5355 16.8747 19.2426L12.6321 15L16.8747 10.7574C17.1676 10.4645 17.1676 9.98959 16.8747 9.6967C16.5818 9.40381 16.1069 9.40381 15.814 9.6967L11.0411 14.4697ZM20.1428 14.25L11.5714 14.25L11.5714 15.75L20.1428 15.75L20.1428 14.25Z"
                      fill="#FAEEDF"
                    />
                  </svg>
                </button>
                <button type="button" className="navigation-button-variation next-arrow" onClick={handleNext}>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="15" cy="15" rx="15" ry="15" transform="rotate(-90 15 15)" fill="#181818" />
                    <path
                      d="M18.9587 15.5303C19.2516 15.2374 19.2516 14.7626 18.9587 14.4697L14.1857 9.6967C13.8928 9.40381 13.418 9.40381 13.1251 9.6967C12.8322 9.98959 12.8322 10.4645 13.1251 10.7574L17.3677 15L13.1251 19.2426C12.8322 19.5355 12.8322 20.0104 13.1251 20.3033C13.418 20.5962 13.8928 20.5962 14.1857 20.3033L18.9587 15.5303ZM9.85693 15.75L18.4284 15.75L18.4284 14.25L9.85693 14.25L9.85693 15.75Z"
                      fill="#FAEEDF"
                    />
                  </svg>
                </button>
              </>
            )}
          </Swiper>
        </>
      ) : null}
      {showAlert && <AddToCartAlert message={alertMessage} setShowAlert={setShowAlert} linkToProduct={linkToProduct} />}
    </Section>
  );
};

export default ProductListingBlock;
